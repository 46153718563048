<template>
  <div>
    <b-card class="mb-1">
      <b-card-header class="m-0 p-0">
        <div class="card-title">{{ $t('suggestion.header') }}</div>
        <b-button v-b-toggle.collapse-3 variant="text-primary">
          <span class="when-open">{{ $t('suggestion.descriptionButton.hide') }}</span>
          <span class="when-closed">{{ $t('suggestion.descriptionButton.show') }}</span>
          {{ $t('suggestion.descriptionButton.description') }}
          <span class="when-open"><feather-icon icon="ChevronUpIcon"/></span>
          <span class="when-closed"><feather-icon icon="ChevronDownIcon"/></span>
        </b-button>
      </b-card-header>
      <b-collapse id="collapse-3" visible>

        <div ref="container">
          <div class="mt-2 text-left w-100">
            <vs-row class="mb-1">
              {{ $t('suggestion.description') }}
            </vs-row>
            <b-alert show variant="warning" dismissible>
              <h4 class="alert-heading">
                {{ $t('suggestion.warningHeader') }}
              </h4>
              <p class="m-1">
                {{ $t('suggestion.warningDescription') }}
              </p>
            </b-alert>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-card :title="$t('suggestion.subHeader')">
      <div ref="container">
        <vs-row class="mb-1">
          {{ $t('suggestion.shortDescription') }}
        </vs-row>
        <vs-table class="stretchTableOne" :data="[1]">
          <template slot="thead">
            <vs-th v-for="(name, columnIndex) in columnNamesView" :key="columnIndex">
              <span>{{ $t('suggestion.tableHeaders.' + name) }}</span>
            </vs-th>
          </template>
          <vs-tr v-for="(item,key) in getAvailableFields" :key="key">
            <vs-td>
              {{ getFieldTranslation(item.label) }}
            </vs-td>
            <vs-td>
              <vs-switch v-if="item.label=='suggestions'" :value="false" :disabled="true">
                <template v-slot:off>
                  {{ $t('search.settings.enabled_off') }}
                </template>
              </vs-switch>
              <vs-switch v-else v-model="item.useAggregate" class="inline-flex" @input="saveSettings()">
                <template v-slot:on>
                  {{ $t('search.settings.enabled_on') }}
                </template>
                <template v-slot:off>
                  {{ $t('search.settings.enabled_off') }}
                </template>
              </vs-switch>
            </vs-td>
            <vs-td>
              <vs-switch v-if="item.label=='suggestions'" :value="true" :disabled="true">
                <template v-slot:on>
                  {{ $t('search.settings.enabled_on') }}
                </template>
              </vs-switch>
              <vs-switch v-else v-model="item.status" class="inline-flex" @input="saveSettings()">
                <template v-slot:on>
                  {{ $t('search.settings.enabled_on') }}
                </template>
                <template v-slot:off>
                  {{ $t('search.settings.enabled_off') }}
                </template>
              </vs-switch>
            </vs-td>
          </vs-tr>
        </vs-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import SearchInstanceStore from '@/store/search/searchInstance'

export default {
  components: {},
  data() {
    return {
      columnNamesView: ['field', 'dataFromAggregate', 'status'],
    }
  },
  computed: {
    getAvailableFields() {
      return store.state.suggestion.suggestion
    },
    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    getFieldTranslation(field) {
      return this.$te(`weightsSettings.fields.${field}`) ? this.$t(`weightsSettings.fields.${field}`) : field
    },
    getData() {
      return store.dispatch('suggestion/getData', this.getSearchId)
    },
    saveSettings() {
      const suggestionsToSave = []
      const availableFields = this.getAvailableFields

      Object.keys(availableFields).forEach(suggestionLabel => {
        if (suggestionLabel === 'suggestions') {
          suggestionsToSave.push({ label: 'suggestions', status: true, useAggregate: false })
        } else if (availableFields[suggestionLabel].status || availableFields[suggestionLabel].useAggregate) {
          suggestionsToSave.push(availableFields[suggestionLabel])
        }
      })
      return store.dispatch('suggestion/store', { searchId: this.getSearchId, suggestions: suggestionsToSave })
    },
  },
}
</script>

<style scoped>

.stretchTableOne tr td:last-of-type,
.stretchTableOne tr th:last-of-type {
  text-align: right;
  float: right;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
